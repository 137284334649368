import { ConfigType } from '../../../../types/configuration';

export interface friendReferraType {
    config: ConfigType,

}

export const boxTypeMapNames = {
    referrer: 'Referrer',
    referee: 'Referee'
}

export interface friendReferraPropsType {
    config: ConfigType
}

export interface referralFormPropType {
    referrerBox: boolean,
    refereeBox: boolean,
    referralRewards: {
        referrer: {
            amount: number,
            currencyId: string,
            notificationChannel: string
        },
        referee: {
            amount: number,
            currencyId: string,
            notificationChannel: string
        },

    }
}
