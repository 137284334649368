import { useState } from 'react';
import {notificationType} from './../../../../src/types/notifications'
import { defaultNotifState } from './../../../constants/currency-constants';
import { FriendReferralForm } from './FriendReferralForm';
import { Form, Formik } from 'formik';
import { saveConfig } from '../../../utils/s3FileUtils';
import { useParams } from "react-router-dom";
import { Notification } from '../../../common/Notification/Notification';
import { referralRewardsValidationSchema } from './FriendReferralValidationSchema'
import {friendReferraType, referralFormPropType } from './constants/referralRewards-constants'

export const referralFormInitialValues: referralFormPropType = {
    referrerBox: false,
    refereeBox: false,
    referralRewards: {
        referrer: {
            amount: 0,
            currencyId: '',
            notificationChannel: 'ses'
        },
        referee: {
            amount: 0,
            currencyId: '',
            notificationChannel: 'ses'
        },

    }
};

const FriendReferral = ({config}: friendReferraType) => {
    const [notificationState, setNotificationState] = useState<notificationType>(defaultNotifState);

    const updatedConfigValues = (formikValues) => {
        let tempConfig = config;
        if (formikValues?.referralRewards) {
            tempConfig.referralRewards = formikValues?.referralRewards
        }
        if (!formikValues?.referralRewards?.referralRewards?.referrer.notificationChannel) {
            delete tempConfig?.referralRewards?.referrer?.notificationChannel
        }
        if (!formikValues?.referralRewards?.referralRewards?.referee?.notificationChannel) {
            delete tempConfig?.referralRewards?.referee?.notificationChannel
        }
        if (!formikValues?.referrerBox && tempConfig?.referralRewards?.referrer) {
            delete tempConfig?.referralRewards.referrer
        }
        if (!formikValues?.refereeBox && tempConfig?.referralRewards?.referee) {
            delete tempConfig?.referralRewards.referee
        }
        return tempConfig
    }
    let urlParams = useParams();
    return (
        <>
        <Formik
                initialValues={referralFormInitialValues}
                enableReinitialize
                validationSchema={referralRewardsValidationSchema}
                onSubmit={async (values) => {
                    try {
                        await saveConfig({ urlParams, submitData: updatedConfigValues(values), setNotificationState });
                        setNotificationState({
                            open: true,
                            title: 'Referral settings were updated successfully',
                            content: 'Referral settings were saved!',
                            level: 'success',
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }}>
                <Form>
                <>
                <Notification
                setNotificationState={setNotificationState}
                notificationState={notificationState}
                />
                <FriendReferralForm
                    config={config}
                />
                </>
                </Form>
            </Formik>
        </>
    )
}

export { FriendReferral }

