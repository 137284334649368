import { Box, Button, Typography, Icon, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState, useEffect } from 'react';
import { ConfigType } from '../../../types/configuration';
import { SelectOption } from './../../../components/selectComponents/SelectOption'
import { useFormikContext } from 'formik';
import { FormikContextType } from './../../../../node_modules/formik/dist/types';
import { InputComponent } from '../../../components/inputComponents/InputComponent';
import { CheckboxComponent } from '../../../components/inputComponents/CheckboxComponent';
import { referralFormInitialValues } from './FriendReferral'
import { boxTypeMapNames, friendReferraPropsType } from './constants/referralRewards-constants'
import './FriendReferral.css'

const boxTypes: string[] = ['referrer', 'referee']

const ReferralInputBox = ({formik, boxToggle, sesToggle, inputFieldsDisabled, currencyOptionList, boxType}) => {
    return (
        <Box className='outerBoxStyle'>
        <CheckboxComponent
        customBoxClass='referralEnableBoxStyle'
        testingId={`${boxType}Box`}
        fieldName={`${boxType}Box`}
        label={`${boxTypeMapNames[boxType]} Rewards`}
        customAction={boxToggle}
        />
            <Box className={inputFieldsDisabled ? 'innerBoxStyleActive' : 'innerBoxStyleDisabled'}>
            <div className='toolTipDiv'>
            <CheckboxComponent
                customBoxClass='sesSelectorBox'
                disabledCondition={!inputFieldsDisabled}
                testingId={`${boxType}-SESCheckbox`}
                fieldName={`${boxType}SESCheckbox`}
                label={`Enable ${boxTypeMapNames[boxType]} Notifications`}
                customAction={sesToggle}
            />
            <Tooltip
                arrow
                sx={{ marginTop: '40px', marginRight: '25px' }}
                classes={{ tooltip: 'tooltip_text' }}
                title={'The notifications are sent by the AWS SES (Simple Email Service)'}
                placement={'bottom'}>
                <Icon
                    className='icon-style'
                    fontSize={'medium'}>
                    <HelpOutlineIcon />
                </Icon>
            </Tooltip>
            </div>
            <Typography className={`referralAllocationLabel${inputFieldsDisabled ? 'Active' : 'Disabled'}`}>
                Add Currency Allocation
            </Typography>
                <SelectOption
                    title = {'Currency ID'}
                    titleVariant = {'body2'}
                    dataTestId={`${boxType}-currIdSelect`}
                    selectClass={'currencyIdSelectStyle'}
                    disabled={!inputFieldsDisabled}
                    formikValue= {`referralRewards.${boxType}.currencyId`}
                    optionsList={currencyOptionList}
                    />
                <InputComponent
                titleVariant = {'body2'}
                title={'Amount'}
                inputClassName={'currencyAmountInputStyle'}
                disabled={!inputFieldsDisabled}
                formik={formik}
                formikLabel={`referralRewards.${boxType}.amount` || ''}
                inputValue={formik.values?.referralRewards?.[boxType]?.amount || ''}
                />
            </Box>
        </Box>
    )
}

const FriendReferralForm = ({config}: friendReferraPropsType) => {
    const [referrerFieldsEnabled, setReferrerFieldsEnabled ] = useState<boolean>(!!config.referralRewards?.referrer);
    const [refereeFieldsEnabled, setRefereeFieldsEnabled ] = useState<boolean>(!!config.referralRewards?.referee);


    const clearValuesOnBoxToggle = (boxToClear: string) => {
        formik.setFieldValue((boxToClear === 'referrerBox') ? 'referralRewards.referrer' : 'referralRewards.referee', undefined )
        formik.setFieldValue((boxToClear === 'referrerBox') ? 'referrerSESCheckbox' : 'refereeSESCheckbox' , undefined)
    }

    const inferStateFromConfig = (inputConfig : ConfigType, formik: FormikContextType<unknown>) => {
        if (!inputConfig?.referralRewards) {
            formik.setValues(referralFormInitialValues);
            return;
        }

        Object.keys(inputConfig?.referralRewards).forEach(key => {
            formik.setFieldValue(`referralRewards[${key}]`, inputConfig?.referralRewards[key])
            if(key === 'referrer') {
                formik.setFieldValue('referrerBox', true)
                if (inputConfig?.referralRewards?.referrer?.notificationChannel) {
                    formik.setFieldValue('referrerSESCheckbox', true)
                    formik.setFieldValue('referralRewards.referrer.notificationChannel', 'ses')
                } else {
                    formik.setFieldValue('referrerSESCheckbox', false)
                    formik.setFieldValue('referralRewards.referrer.notificationChannel', undefined)
                }
            }
            if(key === 'referee') {
                formik.setFieldValue('refereeBox', true)
                if (inputConfig?.referralRewards?.referee?.notificationChannel) {
                    formik.setFieldValue('refereeSESCheckbox', true)
                    formik.setFieldValue('referralRewards.referee.notificationChannel', 'ses')
                } else {
                    formik.setFieldValue('refereeSESCheckbox', false)
                    formik.setFieldValue('referralRewards.referee.notificationChannel', undefined)
                }
            }
        })
}
    const boxToggle = (inputEvent: { target: { checked: boolean | ((prevState: boolean) => boolean); name: string; }; }) => {
        const boxToggleSetMap = {
            referrerBox: setReferrerFieldsEnabled,
            refereeBox: setRefereeFieldsEnabled
        }
        if (!inputEvent?.target?.checked) {
            clearValuesOnBoxToggle(inputEvent.target?.name)
        } else {
            inputEvent.target?.name === 'referrerBox' ?
            formik.setFieldValue('referralRewards.referrer', referralFormInitialValues.referralRewards.referrer) :
            formik.setFieldValue('referralRewards.referee', referralFormInitialValues.referralRewards.referee)
        }
        return boxToggleSetMap[inputEvent.target?.name](inputEvent?.target?.checked);
    }

    const sesToggle = (inputEvent: { target: { checked: boolean | ((prevState: boolean) => boolean); name: string | number; }; }) => {
        const sesFieldMap = {
            referrerSESCheckbox: 'referralRewards.referrer.notificationChannel',
            refereeSESCheckbox: 'referralRewards.referee.notificationChannel'
        }
        formik.setFieldValue(sesFieldMap[inputEvent.target?.name], (inputEvent?.target?.checked ? 'ses' : undefined))
    }

    const formik = useFormikContext()

    useEffect(() => {
        inferStateFromConfig(config, formik);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config])

    return (
        <>
        <div className='refDivStyle'>
        {boxTypes.map((type: string, index: number) =>
                        <ReferralInputBox
                        key={index}
                        formik={formik}
                        boxToggle={boxToggle}
                        sesToggle={sesToggle}
                        inputFieldsDisabled={(type==='referrer')? referrerFieldsEnabled : refereeFieldsEnabled}
                        currencyOptionList={config?.configurationParameters?.currencies}
                        boxType={type}
                        />

                    )}
            </div>
                    <Box sx={{paddingLeft: '230px', marginTop: '30px', justifyContent: 'center', display: 'flex'}}>
                    <Button variant='outlined' disabled={false} onClick={() =>  formik.resetForm({ values: formik.initialValues })}>
                        DISCARD
                    </Button>
                    <Button sx={{width: '100px', marginLeft: '15px'}} variant='contained' color='primary' type='submit'>
                        SAVE
                    </Button>
                    </Box>
        </>
    )
}

export { FriendReferralForm }
